import SaturacionPic from "../../components/SaturacionPic/SaturacionPic"
import React from 'react'

const SaturacionPage = () => {
    return (
        <>
            <SaturacionPic />
        </>
    )
}

export default SaturacionPage