import CriaturasPic from "../../components/CriaturasPic/CriaturasPic"
import React from 'react'

const CriaturasPage = () => {
    return (
        <>
            <CriaturasPic />
        </>
    )
}

export default CriaturasPage