import "./LibrosPic.css";
import data from "../../data/imagesLibros.json";

const LibrosPic = () => {
  return (
    <section className="section-container-libros">
      <article className="article-container">
        <h2>LIBROS</h2>
      </article>
      <article>
        <div className="wrapper-libros">
          {data.data.map((item, index) => (
            <div key={index} className="wrapper-libros-images">
              <a href={item.url} target="_blank" rel="noreferrer">
                <img loading="lazy" src={item.link} alt={item.text} />
                <h2>{item.text}</h2>
              </a>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default LibrosPic;
