import EnsenanzaPic from "../../components/EnsenanzaPic/EnsenanzaPic"
import React from 'react'

const EnsenanzaPage = () => {
    return (
        <>
            <EnsenanzaPic />
        </>
    )
}

export default EnsenanzaPage