import './ContactPage.css'
import ContactText from '../../components/ContactText/ContactText'
import React from 'react'

const ContactPage = () => {
    return (
        <>
            <ContactText></ContactText>
        </>
    )
}

export default ContactPage

