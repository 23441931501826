import "./HomePage.css";
import React from "react";
// import data from "../../data/imagesHome.json";

const HomePage = () => {
  return (
    <>
      <div className="navigation"></div>
      <div className="container_img_home"></div>
      <div className="container_img_home_mobile"></div>
      {/* {data.data.map((item, index) => (
          <div key={index} className="img_karynga">
            <img
              alt={item.text}
              src={item.link}
              className={item.className}
            />
          </div>
        ))} */}
    </>
  );
};

export default HomePage;
