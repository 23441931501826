
import React from 'react'

const ContactText = () => {
    return (
      <div className="section-container">
        <section>
          <article className="article-container">
            <h2>CONTACTO</h2>
          </article>
          <article className="text-container">
            <p class="text-contact">
              <a
                href="mailto:karynga@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <p>karynga.art@gmail.com</p>
                </li>
              </a>
              <br></br>
              <a
                href="https://instagram.com/karynga_art"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <p>Instagram</p>
                </li>
              </a>
              <br></br>
            </p>
          </article>
        </section>
      </div>
    );
}

export default ContactText