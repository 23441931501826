import React from "react";
import "./ObraText.css";

const ObraText = () => {
  return (
    <div>
      <section className="section-container">
        <article className="article-container">
          <h2>OBRA</h2>
        </article>
        <article className="text-container">
          <div>
            {/* <p className="text-obra">
              Museo, memoria, historia, mercado, especulación, marketing: la
              propuesta de karynga se inspira en el engaño que es la sociedad
              del bienestar, en el cinismo destructivo de la democracia y el
              arte como agente blanqueador de los crímenes del Estado. Nuestra
              cultura es el estrés, la globalización, el bullicio capitalista y
              una incipiente epidemia de depresión que el Sistema busca atajar
              por medio de fármacos low cost. Dicha falacia, maquillada por un
              tipo de arte complaciente que oculta el engaño a la sociedad, es
              el caldo de cultivo de la obra de karynga. Un sinfín de coloridos
              monstruos estructuran una narrativa donde la sociedad del primer
              mundo queda retratada por medio de sus costumbres, actitudes y
              cotidianidad. Dicha narración parte con la obra{" "}
              <strong>Esnifando en París con el Sistema Nervioso (2008)</strong>{" "}
              y se desarrolla hasta nuestros días siguiendo un mismo patrón
              donde estilos pictóricos como el neoexpresionismo, la abstracción
              gestual y la figuración post-pop confluyen con el expresionismo
              abstracto. El objeto tangible deja de tener valor para
              transformarse en un medio de representación vehicular en el que
              viajar por las desavenencias del mundo civilizado. El propósito de
              la obra de karynga, también en literatura, es poner en tela de
              juicio esa forma de cultura donde el objeto material es propicio
              para la especulación, y pierde todo su significado al pasar a
              formar parte de un mercado, momento en que el arte se vuelve
              complaciente. En el desarrollo de la performance, con el fin de
              hallar cierto equilibrio entre voluntad revolucionaria e
              hipocresía mercantil, algunas obras pueden sufrir daños
              irreversibles. Es de este modo que el artista busca dar
              consistencia a una propuesta que podría definirse en unas pocas
              palabras: ¡contra la pared, hijo de puta, esto es un atraco!
            </p> */}
            <p className="text-obra">
              La propuesta de karynga retrata el estado de alienación de una
              sociedad marcada por el estrés, la globalización, el bullicio
              capitalista y una incipiente epidemia de depresión y ansiedad que
              el Estado pretende atajar, sin demasiado éxito, por medio de
              fármacos low cost; un inusual manual costumbrista que ilustra el
              prolongado colapso de Occidente. Un sinfín de coloridos monstruos
              estructuran una narrativa donde la sociedad del primer mundo queda
              retratada por medio de sus dinámicas, actitudes y cotidianidad.
              Dicha narración parte con la obra{" "}
              <strong>Esnifando en París con el Sistema Nervioso (2008)</strong>{" "}
              y se desarrolla hasta nuestros días siguiendo un mismo patrón
              donde estilos pictóricos como el neoexpresionismo, la abstracción
              gestual y la figuración post-pop confluyen con el expresionismo
              abstracto. El objeto tangible deja de tener valor para
              transformarse en un medio de representación vehicular en el que
              viajar por las desavenencias del mundo civilizado. En el
              desarrollo de la performance, a fin de hallar cierto equilibrio
              entre voluntad crítica e hipocresía mercantil, algunas obras
              podrían sufrir daños irreversibles; de ahí surge el arte de
              destrucción, puesta en práctica por medio de viscerales
              performances donde creación pictórica y destructivismo se dan la
              mano. Es de este modo que el artista busca dar consistencia a una
              propuesta que podría definirse en unas pocas palabras: ¡contra la
              pared, hijo de puta, esto es un atraco! 
            </p>
          </div>
        </article>
      </section>
    </div>
  );
};

export default ObraText;
