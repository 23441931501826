import './ExpoPage.css'
import React from 'react'
import ExpoText from '../../components/ExpoText/ExpoText'

const ExpoPage = () => {

    return (
        <ExpoText></ExpoText>
    )
}

export default ExpoPage