import React from 'react'
import ObraText from '../../components/ObraText/ObraText'

const ObraPage = () => {

    return (
        <>
            <ObraText></ObraText>
        </>
    )
}

export default ObraPage