import HumanidadPic from "../../components/HumanidadPic/HumanidadPic"
import React from 'react'

const HumanidadPage = () => {
    return (
        <>
            <HumanidadPic />
        </>
    )
}

export default HumanidadPage