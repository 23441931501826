import React from 'react'
import LibrosPic from '../../components/LibrosPic/LibrosPic'

const LibrosPage = () => {
    return (
        <>
            <LibrosPic></LibrosPic>
        </>
    )
}

export default LibrosPage