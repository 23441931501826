import AglomeracionesPic from "../../components/AglomeracionesPic/AglomeracionesPic"
import React from 'react'

const AglomeracionesPage = () => {
    return (
        <>
            <AglomeracionesPic />
        </>
    )
}

export default AglomeracionesPage