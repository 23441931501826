import "./ExpoText.css";
import React from "react";
import "./ExpoText.css";

const ExpoText = () => {
  return (
    <div>
      <section className="section-container">
        <article className="article-container">
          <h2>EXPOSICIONES</h2>
        </article>
        <article className="text-container">
          <div className="text-expo">
            <strong>
              <p className="expo-title">1. Exposiciones individuales:</p>
            </strong>
            <p className="expo-subtitle">
              Marginal - Agosto 2016 - (Capitanía marítima, Baiona)
            </p>
            <strong>
              <p className="expo-title">2. Exposiciones colectivas:</p>
            </strong>
            <p className="expo-subtitle">
              UNTITLED#1 - Septiembre 2006 - (Casa del libro, Vigo)
            </p>
            <p className="expo-subtitle">
              UNTITLED#2 - Septiembre 2007 - (Casa del libro, Vigo)
            </p>
            <p className="expo-subtitle">
              Karynga X Santi Vaz - Junio 2014 (Capitanía marítima, Baiona)
            </p>
            <p className="expo-subtitle">
              HAUSGÜARMING - Marzo 2021 (Studio 66, Barcelona)
            </p>
            <p className="expo-subtitle">
              INVASION - Abril 2021 (Bridge 48, Barcelona)
            </p>
            <p className="expo-subtitle">
              Art Against Humanity - Agosto 2021 - (Buena Onda, Barcelona)
            </p>
            <p className="expo-subtitle">
              Hotassbridge - Septiembre 2021 - (Bridge 48, Barcelona)
            </p>
            <p className="expo-subtitle">
              Hemera Hub - Agosto 2024 - (Krucopia, Barcelona)
            </p>
            <p className="expo-subtitle">
              Inauguración - Septiembre 2024 - (La Galería, Madrid)
            </p>
            <strong>
              <p className="expo-title">3. Ferias de arte:</p>
            </strong>
            <p className="expo-subtitle">
              BúnkerFest - Junio 2012 - (Espacio Vías, León)
            </p>
          </div>
        </article>
      </section>
    </div>
  );
};

export default ExpoText;
